<template>
	<div class='h100'>
		<Navbar :showBack="true" :ifSetData="true" @updataAllData="updataAllData" backgroundColor="#FF9941" color="#fff">Existing Investments</Navbar>
		<div class="wrapper h90 OverflowYAuto">
			<!-- 表格 -->
			<ElementTable :configData="disabled ? configDataDis : configData" :tableData="existing_investments" height="400">
				<el-table-column slot="operation" label="Action">
					<template slot-scope="scope">
						<i class="el-icon-edit" @click="editDetails(scope.row)"></i>
						<i class="el-icon-delete ml20px" @click="deleteDetails(scope.row)"></i>
					</template>
				</el-table-column>
			</ElementTable>
			<!-- 其他资产 -->
			<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex" v-if="!disabled">
				<img src="@/assets/img/add.png" alt="" @click="AddDetails" style="margin-left: 98%;">
			</Navbar>
			<!-- 总资产 -->
			<div class="ml60 mt20px mb20px" v-if="!disabled" style="color: #606266;">Total <span style="color: black;">S$ {{$formatMoney(total)}}</span></div>
			<!-- 编辑、新增弹窗 -->
			<ElementDialog Title="Investment" :Visible.sync="Dialog.visible">
				<el-form slot="content" :model="InvestmentForm" ref="InvestmentForm" class="my-form">
					<el-row>
						<el-col :span="12">
							<el-form-item label="Name of Assets" prop="assets_name">
								<el-input v-model="InvestmentForm.assets_name" @input="InvestmentForm.assets_name = $util.inputReg(InvestmentForm.assets_name)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Type of Investment" prop="investment_type">
								<el-select v-model="InvestmentForm.investment_type" placeholder="">
									<el-option v-for="item in investmentTypeOptions" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="Cash/CPF/SRS/Others" prop="cach_cpf_srs">
								<el-select v-model="InvestmentForm.cach_cpf_srs" placeholder="">
									<el-option v-for="item in cachCpfSrsOptions" :key="item.code" :label="item.name" :value="item.code" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Current Market Value(S$)" prop="current_value">
								<el-input v-model="InvestmentForm.current_value" type="number"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="Annualized Regular Investment Amount,if any(S$)" prop="annualised_investment_amount" style="width: 95%;">
								<el-input v-model="InvestmentForm.annualised_investment_amount" type="number" class="myInput"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div slot="BtnSolt" class="TextAlginCenter">
					<el-button type="warning" v-if="Dialog.status == 'Add'" @click="SaveAdd">Add</el-button>
					<el-button type="warning" v-if="Dialog.status == 'Edit'" @click="SaveUpdate">Update</el-button>
					<el-button type="warning" @click="Dialog.visible = false">Cancel</el-button>
				</div>
			</ElementDialog>
		</div>
	</div>
</template>

<script>
import ElementTable from '../../../../components/ElementTable.vue';
import Navbar from "../../../../components/Navbar.vue";
import ElementDialog from '../../../../components/ElementDialog/index.vue';

export default {
	components: { Navbar, ElementTable, ElementDialog },
	data () {
		return {
			existing_investments: [],
			// 弹窗form表单数据
			InvestmentForm: {
				assets_name: '',
				investment_type: '',
				cach_cpf_srs: '',
				current_value: '',
				annualised_investment_amount: '',
				existing_investments_id: Date.now().toString()
			},
			investmentTypeOptions: global.typeOfInvestment,
			cachCpfSrsOptions: global.cachCpfSrs,
			// Dialog弹窗
			Dialog: {
				visible: false,
				status: "Add",
			},
			disabled: false,
			kycAllData: {}
		};
	},
	computed: {
		total () {
			let sum = 0
			this.existing_investments.map(res => {
				sum += parseFloat(res.current_value)
			})
			return sum
		},
		configData () {
			return [
				{
					slot: "operation"
				}, {
					label: "Name of Assets",
					prop: "assets_name"
				}, {
					label: "Type of Investment",
					prop: "investment_type"
				}, {
					label: "Cash/CPF/SRS/Others",
					prop: "cach_cpf_srs"
				}, {
					type: "amount",
					label: "Current Market Value",
					prop: "current_value"
				}, {
					type: "amount",
					label: "Annualized Regular Investment Amount,if any",
					prop: "annualised_investment_amount",
					width: "400px"
				}
			]
		},
		configDataDis () {
			return [
				{
					slot: "Annualizedamount",
					label: "Annualized Regular Investment Amount,if any",
					prop: "annualised_investment_amount",
					width: "400px"
				}, {
					label: "Name of Assets",
					prop: "assets_name"
				}, {
					label: "Type of Investment",
					prop: "investment_type"
				}, {
					label: "Cash/CPF/SRS/Others",
					prop: "cach_cpf_srs"
				}, {
					slot: "amount",
					label: "Current Market Value",
					prop: "current_value"
				}
			]
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {},
	methods: {
		// 返回上一级页面时，将本页值赋kycalldate
		updataAllData () {
			if (!this.disabled) {
				this.kycAllData.kyc.client[this.kycClient].existing_investments_insurance.existing_investments = this.existing_investments
				this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			}
		},
		// 删除信息
		deleteDetails (row) {
			this.$confirm('confirm to delete?', 'Alert', {
				confirmButtonText: 'CONFIRM',
				cancelButtonText: 'CANCEL',
				type: "info",
			}).then(() => {
				// 删除逻辑
				this.existing_investments.map((temp, index) => {
					if (temp.existing_investments_id === row.existing_investments_id) {
						this.existing_investments.splice(index, 1)
					}
				})
			})
		},
		// 新增亲属信息
		AddDetails () {
			this.InvestmentForm = this.$ResetFrom(this.InvestmentForm)
			this.InvestmentForm.existing_investments_id = Date.now().toString()
			this.openDialog('Add')
		},
		// 编辑亲属信息
		editDetails (row) {
			this.InvestmentForm = JSON.parse(JSON.stringify(row))
			this.openDialog('Edit')
		},
		// 打开弹窗
		openDialog (status) {
			this.Dialog.visible = true
			this.Dialog.status = status
		},
		// 新增
		SaveAdd () {
			this.$refs.InvestmentForm.validate(valid => {
				if (valid) {
					this.existing_investments.push(this.InvestmentForm)
					this.Dialog.visible = false
				}
			})
		},
		// 编辑
		SaveUpdate () {
			this.existing_investments.map((temp, i) => {
				if (temp.existing_investments_id == this.InvestmentForm.existing_investments_id) {
					this.existing_investments.splice(i, 1, this.InvestmentForm)
				}
			})
			this.Dialog.visible = false
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.existing_investments = this.kycAllData.kyc.client[this.kycClient].existing_investments_insurance.existing_investments
		this.disabled = this.$router.currentRoute.fullPath.includes('View')
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='css' scoped>
span {
	line-height: 24px;
}
.myInput >>> .el-input__inner {
	padding-left: 350px;
}
</style>